import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private spinner: NgxSpinnerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();
        if (localStorage.getItem('token') != null) {
            let token = JSON.parse(localStorage.getItem('token'));
            const cloneRequest = req.clone({
                headers: req.headers.set('Authorization', `Codification ${token.access_token}`)
            });

            return next.handle(cloneRequest)
                .pipe(
                    tap(
                        success => { },
                        error => {
                            if (error.status == 401) {
                                localStorage.removeItem('token');
                                this.router.navigate(['/sessions/signin']);
                            } else if (error.status == 403) {
                                this.router.navigate(['/forbidden']);
                            }
                        },
                    ),
                    finalize(() => {
                        this.spinner.hide();
                    })
                );

        } else {
            return next.handle(req.clone())
                .pipe(
                    tap(
                        success => { },
                        error => {
                            if (error.status == 401) {
                                this.router.navigate(['/sessions/signin']);
                            }
                        },
                    ),
                    finalize(() => {
                        this.spinner.hide();
                    })
                );
        }
    }

}
