import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;

    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {
            name: 'Shopify',
            description: 'Administración de productos y ordenes de shopify',
            type: 'dropDown',
            icon: 'icon-shopify',
            sub: [
                { icon: 'i-Bag-Coins', name: 'Ordenes', state: '/shopify/orders-home', type: 'link' },
                { icon: 'i-Bag-Items', name: 'Kits', state: '/shopify/kits-list', type: 'link' },

            ]
        },
        {
            name: 'Vtex',
            description: 'Administración de productos y ordenes de vtex',
            type: 'dropDown',
            icon: 'icon-vtex',
            sub: []
        },
        {
            name: 'Administración',
            description: 'Administración del sistema',
            type: 'dropDown',
            icon: 'icon-admin',
            sub: [
                { icon: 'i-Receipt-4', name: 'Usuarios', state: '/admin/user/list', type: 'link' },
                { icon: 'i-Bag-Coins', name: 'Tiendas', state: '/admin/store/list', type: 'link' },
                { icon: 'i-Gear', name: 'Parametros', state: '/admin/parameters/list', type: 'link' },

            ]
        }
    ];


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();
}
