import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of, throwError } from "rxjs";
import { catchError, delay, map } from "rxjs/operators";
import { Token } from "../models/token.model";
import { Login } from "../models/login.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { User } from "../models/user.model";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  token: Token;

  formLogin: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });
  resetPwForm: FormGroup = new FormGroup({
    usuaId: new FormControl(0),
    usuaPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
    ]),
    usuavalPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
    ]),
  });
  recoveryPwForm: FormGroup = new FormGroup({
    usuaMail: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
    ]),
  });
  constructor(private store: LocalStoreService, private router: Router, private http: HttpClient) {
    this.checkAuth();
  }
  populateResetPwd(usuaId: number) {
    this.resetPwForm.setValue({
      usuaId: usuaId,
      usuaPassword: '',
      usuavalPassword: ''
    });
  }

  checkAuth() {
    if (localStorage.getItem('token')) {
      this.token = JSON.parse(localStorage.getItem('token'));
    } else {
      this.token = { access_token: '' };
    }
    if (this.token.access_token != undefined) {
      return this.token.access_token.length > 5 ? true : false;
    }
    return false;
  }

  getuser() {
    return of({});
  }

  signin() {
    const user: Login = Object.assign({}, this.formLogin.value);
    return this.http.post(`${environment.urlApi}/auth`, user).pipe(
      map((result: any) => {
        const token: Token = {
          access_token: result.jwt,
        };
        this.saveStorage(token, result.user);
        return true;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  requestResetPw() {
    let itemToReset: User = Object.assign({}, this.recoveryPwForm.value);
    let url = `${environment.urlApi}/users/resetpw/requestresetpw/`;
    return this.http.post(url, itemToReset);
  }
  signout() {
    localStorage.clear();
    this.router.navigateByUrl("/sessions/signin");
  }
  saveStorage(token: Token, user: User): void {
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('name', JSON.stringify(user.usuaName));

    this.token = token;
  }

  updatePw() {
    let itemToCreate: User = Object.assign({}, this.resetPwForm.value);
    let url = `${environment.urlApi}/users/resetpw/saveresetpw/`;
    return this.http.post(url, itemToCreate);
  }
}
