<div class="app-admin-wrap layout-sidebar-compact clearfix sidebar-dark-purple"
    [ngClass]="{'sidenav-open': navService.sidebarState.sidenavOpen}">
    <div class="side-content-wrap">
        <app-sidebar-compact></app-sidebar-compact>
    </div>
    <div class="main-content-wrap d-flex flex-column" [perfectScrollbar]="{suppressScrollX: true}">
        <app-header-sidebar-compact></app-header-sidebar-compact>
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
        <div class="flex-grow-1"></div>
        <div class="module-loader" *ngIf="moduleLoading">
            <div class="spinner spinner-bubble spinner-bubble-primary me-3"></div>
        </div>
    </div>
</div>